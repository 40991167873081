/* @import "~slick-carousel/slick/slick.css"; */
/* @import "~slick-carousel/slick/slick-theme.css"; */

body {
  background-color: #1f2937;
}

#root {
  background-color: #1f2937;
}
.App {
  min-height: 100vh;
  position: relative;
  overflow-x: scroll;
}
mark {
  background: transparent !important;
  background-color: transparent !important;
  color: "#00BF63" !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.mobile-only {
  display: block;
}
.no-mobile {
  display: none;
}

@media (min-width: 990px) {
  .mobile-only {
    display: none;
  }
  .no-mobile {
    display: block;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.side-bar-enabled {
  width: 100%;
  margin: 0 auto;
  margin-right: 0;
  overflow-y: visible;
  min-width: 1320px !important;
}


.cookie-banner {
  width: 100vw;
  height: 200px;
  background-color: #2f2f2f;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100000;
  border: 1px solid #00bf63;
  padding: 30px;
  text-align: center;
}

@media (max-width: 700px) {
  .cookie-banner {
    height: 290px;
  }
}


.cookie-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cookie-banner a {
  color: #00bf63;
}

.cookie-banner button {
  background-color: #00bf63;
  color: #2f2f2f;
  padding: 10px;
  width: 100px;
  border: none;
  margin-right: 20px;
}

.mobile-warning {
  width: 100vw;
  height: 100vh;
  background-color: #1f2937;
  position: fixed;
  top: 0;
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  p {
    font-size: 28px;
    text-align: center;
  }
  svg {
    width: 100px;
    height: 100px;
    path {
      fill: #00bf63;
    }
  }
}